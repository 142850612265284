// Breakpoints
$bp-xsmall: 30em;
$bp-small: 48em; // 768px
$bp-medium: 64em; // 1024px
$bp-large: 85.375em; // 1366px


// Media Queries
$mq-xsmall: "(min-width: #{$bp-xsmall})";
$mq-small: "(min-width: #{$bp-small})";
$mq-medium: "(min-width: #{$bp-medium})";
$mq-large: "(min-width: #{$bp-large})";

// @media only screen and #{$mq-xsmall} => Example

.form-container {
    height: auto;
    box-shadow: 0px 3px 3px -2px rgb(0 0 0 / 20%), 0px 3px 4px 0px rgb(0 0 0 / 14%), 0px 1px 8px 0px rgb(0 0 0 / 12%);
    padding: 50px 25px;
    max-width: 100%;
    margin: 0 auto;
}

.button {
    background-color: #0072ce !important;
    color: #f7f7f7 !important;
    margin: 15px 20px !important;
    position: absolute;
    left: 650px;
}

.brand-header {
    text-align: center;
    font-size: 25px;
    font-weight: bold;
    margin-bottom: 50px;
}

.input-field-wrapper {
    margin-top: 20px;
    width: fit-content;
}

@mixin font-input {
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 20px;
}

.input-field-header-black {
    @include font-input;
    color: black;
}

.input-field-header {    
    @include font-input;
    color: #0072CE;
}

.input-label {
    margin-bottom: 5px;
    font-weight: bold;
}

.total-received {
    position: relative;
}

.group-wrapper {
    margin-bottom: 30px;
}

.add-comments {
	display: grid;
}

.group-bottom {
    margin: 30px 0 20px;
}

.input-field-body {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    #first-name-box {
        max-width: 415px;
    }

    #last-name-box {
        max-width: 400px;
    }

}

.cust-agree-list, .disclosure-list, .dealer-agree-list, .purchasers-bullet {
    padding-left: 15px;
}

.acknowledgment-table {
    overflow-x: auto;
    @media only screen and (max-width: 1500px) {
        overflow-x: auto;
        display: block;
    }
    border-collapse: collapse;

    width: 100%;

    th, td {
        border: 1px solid black;
    }

    th {
        height: 60px;
        background-color: #f2f2f2;
    }

    td {
        padding: 8px;
        min-width: 100px;
    }
}

@mixin logo-placement($height: 40px) {
    height: $height;
    vertical-align: middle;
}

.Chevrolet {
    @include logo-placement;
}

.Buick {
    @include logo-placement(60px);
}

.GMC {
    @include logo-placement;
}

.Cadillac {
    @include logo-placement(125px);
}

.small-column {
    width: 130px;
}

.big-column {
    width: 290px;
}

.medium-column {
    width: 215px;
}

.last-row {
    font-weight: bold;
    text-align: right;
}

.checkbox_area {
    margin: 20px 0;
}

.total-row {
    background-color: #f2f2f2;
}

.amount-cell {
    background-color: #cae6fc;
    @media only screen and (max-width: 1350px) {
        width: 150px;
    }
}

.total-column, .discount-last-row {
    font-weight: bold;
}

.purchasers-bullet li {
    padding-bottom: 10px;
    list-style-type: disc;

    .date-container {
        border-bottom: 1px solid black;
        margin-left: 5px;
        font-weight: 700;
    }
}

.discount-bottom {
    font-weight: bold;
    margin-top: 10px;
}

.qualifierTextbox {
    color: white;
}

.indented-list {
    padding-left: 40px;

    #list {
        list-style-type: upper-alpha;
    }

    #sub {
        list-style: lower-roman;
    }
}

#tag {
    list-style: none;
}

.text {
    margin-bottom: 10px;
    font-weight: 550;
}


.signatureimg2 {
    max-height: 40px;
    margin: 5px;
    max-width: 100%;
}

.initial-display {
    width: 120px;
    max-width: 100%;
    max-height: 40px;
    border-radius: 5px;
}

.signingBox {
    border: 1px solid rgba(114, 112, 112, 0.9);
    width: 100%;
    max-height: 40px;
    border-radius: 5px;
}

.complete-button-container {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    align-content: stretch;
    align-items: center;
}

.table-list {
    list-style-type: none;
    word-break: break-word;
}

.cust-ack-cell {
    word-break: break-word;
    hyphens: auto;
}

.signature-container {
    display: flex;
    align-items: center;
    border: 1px solid rgba(114, 112, 112, 0.9);
    width: 100%;
    height: 40px;
    border-radius: 5px;
}

.initial-container {
    width: 90px;
    height: 30px;
}

.MuiButton-contained.Mui-disabled {
    margin-top: 15px;
}

.signature-section {
    display: flex;
    flex-direction: column;
    flex: 1 1 300px;
    width: 100%;
    max-width: 600px;
}

.complete-form-button {
    width: 100%;
}

.doc-fee-label {
    font-weight: bold;
    padding-left: 5px;
}

.doc-fee-container {
    display: flex;
    align-items: center;
    margin-top: 10px;
}

.cda-disclosure {
    font-size: 12px;
    margin: 10px 5px 5px;
}

.item-container {
    display: flex;
    justify-content: center;
}

.req-field {
    max-width: 100%;
    padding: 10.5px;
    border: gray solid 1px;
    border-radius: 5px;
}

@media only screen and #{$mq-xsmall} {
    .brand-header {
        font-size: 27px;
    }
    .form-container {
        padding: 50px 50px;
    }
    .complete-form-button {
        max-width: 200px;
    }
    .signature-section {
        margin-right: 50px;
    }
}

@media only screen and #{$mq-medium} {
    .form-container {
        padding: 50px 100px;
    }
}

.doc-fee-label {
    font-weight: bold;
    padding-left: 5px;
}
.doc-fee-container {
    display: flex;
    align-items: center;
    margin-top: 10px;
}

.cda-disclosure {
    font-size: 12px;
    margin: 5px;
    margin-top: 10px;
}

#ciaf-discount-information {
	display: flex;
	flex-flow: column;

	.loader {
		align-self: center;
	}
}