.header {
    #headerTitle {
        color: grey;
        display: contents;
        font-family: "Overpass", Arial, sans-serif;
        font-size: 22px;
        flex-wrap: nowrap;
    }

    #headerUserName {
        color: gray;
        display: contents;
        font-family: "Overpass", Arial, sans-serif;
        font-size: 14px;
        flex-wrap: nowrap;
        text-decoration: gray;
    }

    #emptydiv {
        flex-grow: 1;
        position: relative;
    }

    #topnav-right {
        float: right;
        margin-top: 1%;
        margin-bottom: 1%;
    }

    #topnav-left {
        float: left;
        margin-top: 1%;
        margin-left: 5%;
        margin-bottom: 1%;
    }

    .chevrolet {
        image-rendering: -webkit-optimize-contrast !important;
        margin-top: 10px;
        max-width: 130px;
        vertical-align: middle;
    }

    .buick {
        image-rendering: -webkit-optimize-contrast !important;
        max-width: 180px;
        vertical-align: middle;
    }

    .gmc {
        image-rendering: -webkit-optimize-contrast !important;
        max-width: 170px;
        vertical-align: middle;
    }

    .cadillac {
        image-rendering: -webkit-optimize-contrast !important;
        max-width: 120px;
        vertical-align: middle;
    }
}
