// Breakpoints
$bp-xsmall: 30em;
$bp-small: 48em; // 768px
$bp-medium: 64em; // 1024px
$bp-large: 85.375em; // 1366px


// Media Queries
$mq-xsmall: "(min-width: #{$bp-xsmall})";
$mq-small: "(min-width: #{$bp-small})";
$mq-medium: "(min-width: #{$bp-medium})";
$mq-large: "(min-width: #{$bp-large})";

.signatureCanvas {
    width: 1050px;
    height: 140px;
}

.initialCanvas {
    width: 270px;
    height: 90px;
}

.signingBox {
    border: 1px solid rgba(114, 112, 112, 0.9);
    width: 100%;
    max-height: 40px;
    border-radius: 5px;
}

.signatureimg {
    max-height: 40px;
    max-width: 100%;
    border-radius: 5px;
    height: auto;
    width: auto;
}

.initialimg {
    border: 1px solid rgba(114, 112, 112, 0.9);
    width: 120px;
    max-height: 40px;
    border-radius: 5px;
}


.initialBox {
    border: 1px solid rgba(114, 112, 112, 0.9);
    width: 100%;
    max-height: 40px;
    border-radius: 5px;

}

.MuiButton-outlined {
    margin-top: 5px;
}

@media only screen and (max-width: 600px) {
    .signatureimg {
        max-height: 40px;
    }
    .signatureCanvas {
        width: 600px;
        height: 80px;
    }
}

@media only screen and (max-width: 300px) {
    .signatureCanvas {
        width: 300px;
        height: 40px;
    }
}
  