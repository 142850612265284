.logout-message {
    width: fit-content;
    height: fit-content;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    border: 1px solid #b9dcfa;

    h3 {
        text-align: center;
        padding: 5%;
    }
}

.logo-image {
    width: 150px;
    padding-top: 20px;
    padding-left: 20px;
}