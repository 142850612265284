.confirmation-page-container {
    display: flex;
    flex-direction: row;
}

.side-image-div {
    min-width: 30%;
    max-width: 35%;
    height: auto;
}

.side-image {
    width: 100%;
    height: auto;
}

.logo-image-div {
    width: 5%;
    height: 5%;
    padding-top: 5%;
    padding-left: 5%;
    padding-bottom: 2%;
}

.logo-image {
    width: auto;
    height: 100%;
}

.code-body {
    width: 50%;
    padding-left: 5%;
}

.request-button {
    background: none !important;
    border: none;
    padding: 0 !important;
    font-family: arial, sans-serif;
    color: #069;
    text-decoration: underline;
    cursor: pointer;
}

.page-spacer,
#next-button {
    margin-bottom: 2.2rem;
}

.timer {
    margin: 0 0 1rem 0;
    font-style: italic;
    color: #e30526;
    width: fit-content;
}
