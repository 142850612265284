.print-modal-container {
    z-index: 1;
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
}

.print-modal-content {
    width: 500px;
    background-color: #fff;
    border: 1px solid lightgray;
}

.print-modal-header {
    background-color: #F7F7F7;
    border-bottom: 1px solid lightgray;
}

.print-modal-header, .print-modal-footer {
    padding-right: 10px;
    padding-left: 10px;
    padding-top: 1px;
    padding-bottom: 1px;
    position: relative;
}

.print-modal-close-icon {
    position: absolute;
    right: 20px;
    top: 20px;
    cursor: pointer;
}

.print-modal-body {
    padding: 10px;
    border-top: 1px solid #eee;
}

.print-modal-buttons {
    display: flex;
    justify-content: center;
    padding-bottom: 5px;
}

.ind-button-div {
    padding: 5px;
}

